<template>
  <el-row>
    <el-col :span="7">
      <el-page-header @back="goBack" content=""></el-page-header>
    </el-col>
    <el-col :span="9" class="py-30">
      <div class="fs-20 mb-30">车辆认证</div>
      <formList
          ref="ruleForm"
          :formLabel="formLabel"
          :form="form"
          formLabelWidth="100px"
          :rules="formRules"
          @handleAvatarSuccess="handleAvatarSuccess"
          @handleRemove="handleRemove"
          @handleClick="submit('ruleForm')"
      >
        <div>
          <el-checkbox v-model="checked" class="checkbox-style">
            我已阅读并同意
            <span class="tc-link cp" @click.prevent="openText('用户服务协议')">《用户服务协议》</span>及
            <span class="tc-link cp" @click.prevent="openText('隐私政策')">《隐私政策》</span>
          </el-checkbox>
        </div>
      </formList>
    </el-col>
    <el-dialog
        top="5vh"
        :visible.sync="dialogVisible"
        width="80%"
        center>
      <div slot="title" class="fs-30 fw-b pt-20 tc-strong">{{ textType }}</div>
      <component :is="assembly" @goPrivacyPolicy="goPrivacyPolicy"></component>
    </el-dialog>
  </el-row>
</template>

<script>
import formList from "@/components/formList";
import publicFun from "@/mixins/public";
import interfaceWechat from "@/mixins/interfaceWechat";

export default {
  name: "carCertification",
  mixins: [publicFun, interfaceWechat],
  components: {
    formList,
    userServicesAgreement: () => import('./file/userServicesAgreement'),
    privacyPolicy: () => import('./file/privacyPolicy')
  },
  data() {
    let mvlicenseValidator = (rule, value, callback) => {
      if (!value) {
        callback();
      } else {
        if (!/^(([京津沪渝冀豫云辽黑湘皖鲁新苏浙赣鄂桂甘晋蒙陕吉闽贵粤青藏川宁琼使领A-Z0-9]{1})+([A-Z0-9]{1})+(([京津沪渝桂蒙宁新藏冀晋辽吉黑苏浙皖赣闽鲁粤鄂湘豫川云贵陕甘青琼])?[A-HJ-Z0-9]{1})+([A-NP-Z0-9]{3})+([A-NP-Z0-9挂学警港澳领试超外]{1})+([A-HJ-NP-Z0-9外])?)|([A-Z0-9]{7})$/.test(value)) {
          callback(new Error('请输入正确的车牌号'));
        } else {
          callback();
        }
      }
    };
    return {
      formLabel: [
        {
          colSpan: 24,
          label: '车牌号',
          type: 'text',
          key: 'mvlicense',
          placeholder: '请输入车牌号'
        },
        {
          colSpan: 24,
          label: '行驶证照片',
          type: 'uploadImage',
          key: 'drivinglicense',
          action: '#',
          autoUpload: false,
        },
        {
          colSpan: 24,
          label: '',
          type: 'slot',
          mb: '0',
          mt: '20px'
        },
        {
          colSpan: 24,
          label: '提交认证',
          type: 'button',
          loading: false,
          buttonType: 'primary',
          width: '100%'
        }
      ],
      form: {
        unionid: '',
        mvlicense: '',
        drivinglicense: '',
        fileList: [],
        fileLists: []
      },
      formRules: {
        mvlicense: [
          { required: true, message: '请输入车牌号', trigger: 'blur' },
          { validator: mvlicenseValidator, trigger: 'blur' }
        ],
        drivinglicense: [
          { required: true, message: '请上传行驶证照片', trigger: 'blur' }
        ]
      },
      checked: false,
      dialogVisible: false,
      assembly: '',
      textType: '',
      infoStatus: ''
    }
  },
  mounted() {
    this.form.unionid = sessionStorage.userinfo ? JSON.parse(sessionStorage.userinfo).unionid : '';
    if (this.$route.params.item !== '"noparams"') {
      let item = JSON.parse(this.$route.params.item);
      this.form.mvlicense = item.mvlicense;
      this.form.drivinglicense = item.drivinglicense;
      item.drivinglicense.split(',').forEach(img => {
        this.form.fileList.push({
          url: img
        });
      });
      this.checked = true;
    }
  },
  methods: {
    openText(type) {
      this.dialogVisible = true;
      this.textType = type;
      this.assembly = type === '用户服务协议' ? 'userServicesAgreement' : 'privacyPolicy';
    },
    goPrivacyPolicy() {
      document.documentElement.scrollTop = 0;
      this.textType = '隐私政策';
      this.assembly = 'privacyPolicy';
    },
    handleAvatarSuccess(file, fileList, _this) {
      const FILE_NAME = file.name;
      let typeList = ['.jpg', '.png', '.jpeg'];
      if (typeList.indexOf(FILE_NAME.substring(FILE_NAME.lastIndexOf('.'))) < 0) {
        this.$message.warning('仅支持上传图片文件');
        let idx = _this.$refs.uploadFile.uploadFiles.findIndex(item => item.uid === file.uid);
        _this.$refs.uploadFile.uploadFiles.splice(idx, 1); // 删掉 类型不符合 的文件
        return false;
      }
      _this.$refs.image.clearValidate();
      let extArr = file.name.split('.');
      this.getBase64(file.raw).then(res => {
        let params = {
          imgStr: res,
          extention: extArr[extArr.length - 1]
        }
        this.$axios
            .post('/uploadStr', params)
            .then((res) => {
              const {
                msg,
                code
              } = res;
              if (code !== 0) {
                if (code === 403) return;
                return this.$message.error(msg || res.data.msg);
              }
              this.form.fileList = fileList;
              this.form.fileLists.push({
                name: file.uid,
                url: msg
              });
              this.$message.success('上传成功');
            })
            .catch((error) => {
              this.$message.error('上传失败');
              let idx = _this.$refs.uploadFile.uploadFiles.findIndex(item => item.uid === file.uid);
              _this.$refs.uploadFile.uploadFiles.splice(idx, 1); // 删掉 上传失败 的文件
            });
      });
    },
    handleRemove(file) {
      for (let i = 0; i < this.form.fileList.length; i++) {
        if (this.form.fileList[i].uid === file.uid) {
          this.form.fileList.splice(i, 1);
        }
        if (this.form.fileLists[i].name === file.uid) {
          this.form.fileLists.splice(i, 1);
        }
      }
    },
    submit(formName) {
      let list = [];
      if (this.form.fileLists.length === 0) {
        this.form.fileLists = this.form.fileList;
      }
      this.form.fileLists.forEach(l => {
        list.push(l.url);
      });
      this.form.drivinglicense = list.join(',');
      this.$refs[formName].$children[0].validate((valid) => {
        if (valid) {
          if (!this.checked) return this.$message.success("请阅读并同意相关协议!");
          if (this.$route.params.item === '"noparams"') {
            this.infoStatus = '新增';
            this.addOrEditInfo('/carownerinfo/insert', this.form, ['formLabel', 3], this.goPage, '', 'carInfo');
          } else {
            this.infoStatus = '修改';
            let params = JSON.parse(JSON.stringify(this.form));
            params.oldMv = JSON.parse(this.$route.params.item).mvlicense;
            this.addOrEditInfo('/carownerinfo/update', params, ['formLabel', 3], this.goPage, '', 'carInfo');
          }
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    }
  }
}
</script>

<style scoped lang="scss">
.checkbox-style {
  ::v-deep {
    .el-checkbox__label {
      white-space: pre-line;
      word-break: break-word;
    }
  }
}
</style>